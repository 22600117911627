import { qs } from "./utils.js"

export function toggleMenuHandler() {
  const toggle = qs("#sl-toggle")
  const eh = qs("#expand-menu")
  const menu = qs(".menu__menu")

  if (eh && menu) {
    eh.addEventListener("change", () => {
      menu.setAttribute("aria-hidden", !eh.checked)
      menu.setAttribute("aria-expanded", eh.checked)
    })
  }

  if (toggle && eh) {
    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        eh.checked = false
        eh.dispatchEvent(new Event("change"))
      }
    })
  }
}
